import(/* webpackMode: "eager", webpackExports: ["CookieConsent"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/component/cookieConsent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopNav"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/component/navigation/DesktopNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/component-library/movestic-blocks/tailwind/component/navigation/MobileNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/vsts/work/1/s/fonder/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/fonder/src/app/ui/global.css");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/proximanova-regular-webfont.woff\",\"weight\":\"400\",\"style\":\"normal\"}],\"variable\":\"--font-proximanova\"}],\"variableName\":\"proximanova\"}");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Neutra2TextTT-Demi.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Neutra2Text_bold.woff2\",\"weight\":\"700\",\"style\":\"bold\"}],\"variable\":\"--font-neutraface\"}],\"variableName\":\"neutraface\"}");
